const Error = () => { 
    return(
        <div style={{width: '100%', height: '100vh', display: 'flex', alignItems:'center', justifyContent: 'center', position: "fixed", top: 0, zIndex: -1}}>            
            <div style={{display: 'flex', flexDirection: 'column', textAlign:'center'}}>
                <h1>Error 404</h1>
                <h1>Page is not found</h1>
            </div>
        </div>
    )
}
export default Error;