import React, { useEffect, useState } from 'react';
import Routers from './app/Router';
import { BrowserRouter } from 'react-router-dom';
import Header from './app/Header';
import './App.css'

function App() {
  const domain = new URL(window.location.href).hostname; 
  const [rightsLvl, setRightsLvl] = useState(0);
  const setter = (param) => {
    setRightsLvl(param);
  }

  return (
    // <Test/>
      <BrowserRouter> 
        <div className="App">
          <Header rights={rightsLvl} domain={domain}/>
          <Routers rightsLvl={rightsLvl} setRightsLvl={setter} domain={domain}/>
        </div>
      </BrowserRouter>
  );
}

export default App;