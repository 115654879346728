export const fetchList = async (domain) =>{
    try{ 
      const responce = await fetch(`https://${domain}/api/mux`, {method: 'GET'})
      if(!responce.ok)throw new Error(responce.message);
      const data = await responce.json();
      return data.muxes;
    }catch(e){
      console.error(e);
      return [];
    }
  }