import React, {useState} from 'react';

const Modal = ({selfRightsLvl, fetchList, domain}) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [modal, setModal] = useState(false);
    const setStatus = () => {
        setModal(!modal);
        document.body.style.overflow = !modal ? "hidden" : "auto";
    }

    const userNameChange = (event) => {
      setUserName(event.target.value)
    };
    const passwordChange = (event) => {
        setPassword(event.target.value);
    }; 
    const nameChange = (event) => {
        setName(event.target.value)
    };
    const emailChange = (event) => {
        setEmail(event.target.value);
    }
    const phonesChange = (event) => {
        setPhone(event.target.value);
    }

    const fetchAddOp = async () => {
        const requestBody = {
            login: userName,
            password: password,
            name: name,
            phone: phone,
            email: email,
            rights: 1
        }
        const requestParams = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(requestBody)
        }
        try {
            const responce = await fetch(`https://${domain}/api/operator/add`, requestParams);
            if(!responce.ok) throw new Error();
        }catch (err) {console.error(err)}
    }

    const request = () => {
        if (
            userName !== '' && 
            name !== '' && 
            password !== ''
        ){  
            fetchAddOp();
            fetchList();
            setStatus();
        }
    }
    
    const close = () => {
        setStatus();
    }

    return(
        <div>
            <button style={selfRightsLvl < 32 ? {display: "none"} : {}} className='requestBtn' onClick={setStatus}>Add</button>
            {modal && <div className='modal-window'>
                <div className='modal-box'>
                    <div className='input-areas'>
                      <label>User Name</label>
                      <input className='inputs' type='text' onChange={userNameChange} />
                    </div>
                    <div className='input-areas'>
                      <label>Password</label>
                      <input className='inputs' type='text' onChange={passwordChange} />
                    </div>
                    <div className='input-areas'>
                      <label>Name</label>
                      <input className='inputs' type='text' onChange={nameChange}/>
                    </div>
                    <div className='input-areas'>
                      <label>Email</label>
                      <input className='inputs' type='text' onChange={emailChange} />
                    </div>
                    <div className='input-areas'>
                      <label>Phone</label>
                      <input className='inputs' type='text' onChange={phonesChange}/>
                    </div>
                    <div className='modal-btns'>
                        <button className='requestBtn' onClick={request}>Save</button>
                        <button className='closeBtn' onClick={close}>Cancel</button>
                    </div>
                </div>
            </div>}
        </div>
    )
}


export default Modal