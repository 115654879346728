import React, {useEffect, useState} from 'react';
import { one } from './../service/rights/one';
import { two } from './../service/rights/two';
import { four } from './../service/rights/four';
import { eight } from './../service/rights/eight';
import { sixteen } from './../service/rights/sexteen';

const rightList = [
  {id: 1, label: 'View users (1)', width: 60.48},
  {id: 2, label: 'View payments (2)', width: 94.85},
  {id: 4, label: 'Add payments (4)', width: 94.85}, 
  {id: 8, label: 'Add currencies rates (8)', width: 97.9}, 
  {id: 16, label: 'View operators (16)', width: 94.39}, 
  {id: 32, label: 'Administrator (all rights are included) (32)', width: 126.75}
] 

const Modal = ({curUserName, domain, fetchList}) => {
  const [modal, setModal] = useState(false);
  const setStatus = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? "hidden" : "auto";
  }
    // const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const nameChange = (event) => {
        setName(event.target.value)
    };
    const emailChange = (event) => {
        setEmail(event.target.value);
    }
    const phonesChange = (event) => {
        setPhone(event.target.value);
    }

    const [rightsLevel, setRightsLevel] = useState(0);
    const [rightLvl, setRightsLvl] = useState(null);
    const listChange = (checked, index, lvl) => {
      let updated = [...rightLvl];
      if (index === 5 && checked) {
        updated = rightList.map(obj => ({lvl: obj.id, access: checked}))
      }else{
        updated[index] = {lvl: lvl, access: checked}
        if (!checked) updated[5].access = false;
      }
      setRightsLvl(updated)
    }

    const rightChoose = () => {
      return <table>
        <tr> 
          {rightList.map(right => 
            <th className='rights-in' style={{width: right.width, padding: '5px'}} key={right.id}>
              {right.label}
            </th>
          )} 
        </tr>
        <tr> 
          {rightList.map((right, index) => {
            const check = rightLvl ? rightLvl[index].access : false;
            return (
              <th className='rights-in' width={right.width} key={right.id}>
                <input type="checkbox" checked={check} onChange={(e) => {listChange(e.target.checked, index, right.id)}}/> 
              </th>)
          })} 
        </tr>
      </table>
    }
  
    const fetchOperator = async() => {
      try{
        const responce = await fetch(`https://${domain}/api/operator/profile/${curUserName}`);
        if(!responce.ok) throw new Error();
        const data = await responce.json();
        setName(data.name || '');
        setEmail(data.email || '');
        // setPassword(data.password || '');
        setPhone(data.phone || '');
        setRightsLevel(data.rights || 0);
      }catch(e){console.error(e)}
    } 

    const fetchChanges = async () => {
      const requestBody = {
        login: curUserName,
        // password: password,
        name: name,
        phone: phone,
        email: email,
        rights: rightsLevel
      }
      const requestParams = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      try{
        const response = await fetch(`https://${domain}/api/operator/profile`, requestParams);
        if(!response.ok) throw new Error(response.message);
        fetchList();
      }catch(e){console.error(e)} 
    }

    const requester = () => {
      if(name !== '' && curUserName !== '') {
        fetchChanges();
        setStatus();
      }
    }

    useEffect(() => {
      let lvl = 0;
      if (rightLvl) rightLvl.forEach(obj => {if(obj.access) lvl += obj.lvl} );
      setRightsLevel(lvl)
    }, [rightLvl]);

    useEffect(() => {
      setRightsLvl([
        {lvl: 1, access: rightsLevel < 32 ? one(rightsLevel) : true}, 
        {lvl: 2, access: rightsLevel < 32 ? two(rightsLevel) : true}, 
        {lvl: 4, access: rightsLevel < 32 ? four(rightsLevel) : true}, 
        {lvl: 8, access: rightsLevel < 32 ? eight(rightsLevel) : true}, 
        {lvl: 16, access: rightsLevel < 32 ? sixteen(rightsLevel) : true}, 
        {lvl: 32, access: rightsLevel >= 32}
      ])
    }, [rightsLevel])

    useEffect(() => {
      fetchOperator();
    }, [curUserName])

    return(
      <div>
        <button onClick={setStatus} className='requestBtn'>Edit</button>
        {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>User Name</label>
                  <input className='inputs' type='text' readOnly value={curUserName}/>
                </div>
                {/* <div className='input-areas'>
                  <label>Password</label>
                  <input className='inputs' type='text' onChange={passwordChange} value={password}/>
                  </div> */}
                <div className='input-areas'>
                  <label>Name</label>
                  <input className='inputs' type='text' onChange={nameChange} value={name}/>
                </div>
                <div className='input-areas'>
                  <label>Email</label>
                  <input className='inputs' type='text' onChange={emailChange} value={email}/>
                </div>
                <div className='input-areas'>
                  <label>Phone</label>
                  <input className='inputs' type='text' onChange={phonesChange} value={phone}/>
                </div>
                {rightChoose()}
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={requester}>Save</button>
                    <button className='closeBtn' onClick={setStatus}>Cancel</button>
                </div>
            </div>
        </div>}
    </div>
    )
}


export default Modal