const Info = ({summary}) => {
    return <table className='reportTotals'>
    <tr>
      <td className='reportsItem'>
        Start Balance
      </td>
      <td className='reportsItem'>
        {summary.start_balance !== undefined ? summary.start_balance : 'XXXX.XX'}
      </td>
    </tr>
    <tr>
      <td className='reportsItem'>
      Services
      </td>
      <td className='reportsItem'>
        {summary.services !== undefined ? summary.services : 'XXXX.XX'}
      </td>
    </tr>
    <tr>
      <td className='reportsItem'>
      Payments
      </td>
      <td className='reportsItem'>
        {summary.payments !== undefined ? summary.payments : 'XXXX.XX'}
      </td>
    </tr>
    <tr>
      <td className='reportsItem'>
      Last Balance
      </td>
      <td className='reportsItem'>
        {summary.end_balance !== undefined ? summary.end_balance : 'XXXX.XX'}
      </td>
    </tr>
    <tr>
      <td className='reportsItem'>
        Total number of decoders
      </td>
      <td className='reportsItem'>
        {summary.decoders !== undefined ? summary.decoders : 'XXXX.XX'}
      </td>
    </tr>
    <tr>
      <td className='reportsItem'>
        Deactivated decoders
      </td>
      <td className='reportsItem'>
        {summary.deactivated !== undefined ? summary.deactivated : 'XXXX.XX'}
      </td>
    </tr>
    </table>
}

export default Info;