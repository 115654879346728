import React, { useState, useEffect } from 'react';

const Auto = ({currencies, setCurrency}) => {
  const [value, setValue] = useState(currencies.currencies && currencies.currencies[0].currency);

  const chooseOption = (option) => {
    setValue(option);
  };

  useEffect(() => {
    setCurrency(value);
  }, [value])
  
  return (
    <div style={{ marginTop: '10px', position: 'relative' }}>
        <select
          className='autocomplete-options'
          value={value}
          onChange={(event) => chooseOption(event.target.value)}
        >
          {currencies.currencies && currencies.currencies.map((option, index) => (
              <option key={index} value={option.currency}>
                {option.currency}
              </option>
          ))}
        </select>
      {/* } */}
    </div>
  );
};

export default Auto;
