import plus from './plus.png';
import arrow from './arrow.png';
import { useEffect, useState } from 'react';
import { fetchMuxData } from './../service/constructor/compMux';
const opts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const Block = ({domain, selfRightsLvl, muxes, setMuxes}) => {
    const [list, setList] = useState(muxes);

    const fetchMux = async () =>{
      fetchMuxData(setList, domain);
    }
    
    const [dialogModal, setDialogModal] = useState(false)
    const [tsId, setTsId] = useState(0); 

    const modalOpen = (id) => {
      setDialogModal(true);
      setTsId(id)
    }

    const modalClose = () => {
      setDialogModal(false)
    }

    const deleteMux = async (id) => {
      try{ 
        const responce = await fetch(`https://${domain}/api/mux/${id}`, {method: 'DELETE'})
        if(!responce.ok)throw new Error(responce.message);
        fetchMux();
        modalClose();
      }catch(e){
        console.error(e);
      }
    }

    const [put, setPut] = useState(false);
    const [group, setGroup] = useState(0);
    
    const handleGroupChoose = (e) => {
      setGroup(parseInt(e.target.value || 0));
    }

    const putMux = async () => {
      const requestBody = {
        ts_id: list.length - 1 >= 0 && list[list.length - 1].ts_id ? list[list.length - 1].ts_id + 1 : 1,
        mux_group: group 
      } 
      const requestParams = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      try{ 
        const responce = await fetch(`https://${domain}/api/mux`, requestParams)
        if(!responce.ok)throw new Error(responce.message);
        fetchMux();
        setPut(false);
        setGroup(0)
      }catch(e){
        console.error(e);
      }
    }

    useEffect(() => {
      setMuxes(list);
    }, [list])

    return(
        <div className='mux'>
          <button className='closeBtn' onClick={fetchMux}>Refresh</button>
           <div className='left-container'> MUX <img className='icon-img' src={arrow} alt="" /> Group </div>
           <div className='whole-mux'>
            <div className='mux-container'>
              {list && list.map(item => (
                <div className='mux-items-container'>
                    <div className='left-container'>
                      {item.ts_id} <img className='icon-img' src={arrow} alt="" />  {item.mux_group}
                    </div>  
                    <button style={selfRightsLvl < 32 ? {display: "none"} : {}} className='requestBtn icon-in' onClick={() => {modalOpen(item.ts_id)}}>
                      <img className='cross-img' src={plus} alt="" />
                    </button> 
                  </div>
                ))
              }
            </div>
            {put &&
            <div>
              <h3>Choose group: </h3>
              <select name="" className='closeBtn' onChange={handleGroupChoose}>
                {opts.map(option => (
                  <option value={option}>{option}</option>
                ))}
              </select>
              <button className='requestBtn' onClick={putMux}>Add</button>
            </div>}
          </div>
          <button style={selfRightsLvl < 32 ? {display: "none"} : {}} className='requestBtn icon-in' onClick={() => {setPut(!put)}}>
            <img src={plus} alt="" />
          </button> 
          {dialogModal &&  
              <div className='modal-window'>
                <div className='modal-box'>
                  <h2>Are you sure you want to delete mux?</h2> 
                  <div className='modal-btns'>
                    <button className='closeBtn' onClick={() => {deleteMux(tsId)}}>Delete</button>
                    <button className='requestBtn' onClick={modalClose}>Cancel</button>
                  </div>
                </div>
              </div>
          }
        </div>
    )
}

export default Block