import AddMod from './Modal'  
import './style.css'
import React, {useState, useEffect} from 'react';
import Pagination from '../components/Pagination';
import { fetchList } from './../service/payments/Payments';
import Loading from '../components/Loading';
import BinBtn from './BinBtn';
import { four } from './../service/rights/four';
const columns = [
  { id: 'user_id', label: 'User ID' },
  { id: 'trans_id', label: 'Trans ID' },
  { id: 'trans_ts', label: 'Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'email', label: 'Email' },
  { id: 'document', label: 'Document' },
  { id: 'oper_name', label: 'Name' },  
  { id: 'btn', label: '' }
];

const Payments = ({setBalance, domain, selfRightsLvl, email, dataL, setDataL, id, currencies}) => {
  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return false;
    }
    return true;
  }
    const [payments, setPayments] = useState(dataL);
    const [first,setFirst] = useState(null);
    const [last,setLast] = useState(null);
    const [errorModal, setErrorModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const modalSet = (status) => {
      setLoadingModal(status);
      document.body.style.overflow = status ? 'hidden' : 'auto';
    }

    const fetchAgain = async (email, first, last, pageNumber, domain) => {
      modalSet(true);
      try{
        if(first !== null || last !== null){
          setErrorModal(!(isValidDate(first) && isValidDate(last)));
          if(isValidDate(first) && isValidDate(last)) {
            const responce = await fetchList(email, first, last, pageNumber, domain);
            setPayments(responce);
          }
        }else{
          const responce = await fetchList(email, first, last, pageNumber, domain);
          setPayments(responce);
        }
        modalSet(false);
      }catch(e){
        console.error(e);
      }
    }

    const fetcher = async () => {
      fetchAgain(email, first, last, pageNumber, domain)
      // fetchPaymentsData(email, first, last, pageNumber, setPayments, domain);
    }

    ////////////////
    // page loading 
    const [pageNumber, setPageNumber] = useState(0);
    
    const pageCount = Math.ceil(payments.count !== undefined ? payments.count / 10 : 0);

    const styler = (id) => {
      if (id === 'btn') return (selfRightsLvl< 32 ? four(selfRightsLvl) : true) ? {border: 0, width: '5%'} : {display: 'none'}
      else return {padding: 'auto'}
    }

    const amountStabiliser = (id, termin, row) => {
      if(id === 'amount') return termin.toFixed(2);
      else return id !== 'btn' ?
      (termin !== '' ?
        termin 
      : 
        'None'
      )
      : <BinBtn 
        transaction={row.trans_id}
        fetchAgain={fetchAgain}
        email={email} 
        first={first} 
        last={last}
        pageNumber={pageNumber} 
        domain={domain}/>
    }

    const displayData = payments.payments && payments.payments.map((row, index) => (
      <tr key={row.id}>
        {columns.map(column => 
          <td key={column.id} 
          style={styler(column.id)}
          > 
            {amountStabiliser(column.id, row[column.id], row)}
          </td>
        )}
      </tr>
    ));

    ////////////////
    // actions
    const handleFirstChange = (e) => {
      setFirst(e.target.value);
    }
    const handleLastChange = (e) => {
      setLast(e.target.value);
    }
    useEffect(() =>{
      if (pageNumber > 0){
        fetcher();
      }
    }, [pageNumber])

    useEffect(() =>{
      setDataL(payments)
    }, [payments])

    return (
      <div className="payments"> 
        <div className='op-table'>
          <div className='paymentsHeader'>
            <div className='input-areas'>
              <label>Date</label>
              <input placeholder='XXXX-XX-XX'
              onChange={handleFirstChange}
              className='inputs' type="text"/>
            </div>
            <div className='input-areas'>
              <label>Last date</label>
              <input placeholder='XXXX-XX-XX'
              className='inputs' type="text"
              onChange={handleLastChange}
              />
            </div>
            <button onClick={fetcher} className='requestBtn padBtn'>Submit</button>
            {errorModal && <span className='red'>Incorrect date</span>}
          </div>
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                  <th key={column.id}
                  style={styler(column.id)}
                  >
                    {column.label}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>
          </table>
          <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber}/> 
        </div>
        <AddMod setBalance={setBalance} selfRightsLvl={selfRightsLvl} fetchAgain={fetcher} domain={domain} currencies={currencies} id={id}/>
        {loadingModal && <Loading/>}
      </div>
    )
}
export default Payments;