import AddMod from './Modal'  
import React, {useState, useEffect} from 'react';
import { fetchCurrencies } from './../service/currencies/comp';
const columns = [
  { id: 'timestamp', label: 'Date' },
  { id: 'currency', label: 'Name of currency' },
  { id: 'rate', label: 'Rate to domestic currency' },
];

const Currencies = ({domain, selfRightsLvl, dataL, setDataL}) => {
    const [currencies, setCurrencies] = useState(dataL);
    const fetchList = () => {
      fetchCurrencies(domain, setCurrencies);
    }

    const displayData = currencies.currencies && currencies.currencies.map((row) => (
      <tr key={row.id}>
        {columns.map(column => 
          <td key={column.id}> 
            {row[column.id] !== '' ? row[column.id] : 'None'}
          </td>
        )}
      </tr>
    ));

    useEffect(() => {
      setDataL(currencies)
    }, [currencies])

    return(
      <div className="packages box"> 
        <button 
        onClick={fetchList} 
        className='closeBtn'>Refresh</button>
        <div className='op-table'>
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                  <th key={column.id}>
                    {column.label}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>
          </table>
        </div>
         <AddMod selfRightsLvl={selfRightsLvl} fetchAgain={fetchList} domain={domain}/>
        
      </div>
    )
}
export default Currencies;