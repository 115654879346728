// import Constr from './ConstructorTable'
import React from 'react'
import './style.css'
import Table from './Table';
import Block1 from './MucGroup';

const Constructor = ({domain, selfRightsLvl, checkedL, packsL, muxes, setCheckedL, setMuxes}) =>{
    return(
        <div className="box">
            < Block1 domain={domain} selfRightsLvl={selfRightsLvl} muxes={muxes} setMuxes={setMuxes}/>
            < Table domain={domain} selfRightsLvl={selfRightsLvl} checkedL={checkedL} packsL={packsL} setCheckedL={setCheckedL}/>
        </div>
    )
}

export default Constructor;