export const fetchList = async (decId, pageNumber, domain) => {
    const requestBody = {
      decoder_id: decId, 
      offset: pageNumber * 10,
      limit: 10,
    }
    const requestParams = {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    try{
      const response = await fetch(`https://${domain}/api/decoders/list`, requestParams);
      if (!response.ok){
        throw new Error()
      }
      const data = await response.json();
      return data;
    }catch(e){
      console.error(e.message)
      return {};
    }
  }
