import {fetchList} from './Decoders';

export const fetchDecodersData = async (decId, pageNumber, setDecoders, domain) => {
    try{
        const requested = await fetchList(decId, pageNumber, domain);
        setDecoders(requested || {});
    }catch(e){
        console.error(e);
        setDecoders({});
    }   
}
