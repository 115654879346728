import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchTypes } from '../service/types/comp';
import { useLocation } from 'react-router-dom';
const Container = styled.div`
  position: relative;
  width: auto;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
`;

const Suggestion = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const AddButton = styled.button`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top: none;
  color: #fff;
  background-color: #146C94;
  cursor: pointer;
  &:hover {
    background-color: #1a8fc6;
  }
`;

const DisButton = styled.button`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #808080;
  cursor: default;
  &:hover {
    opacity: 1;
  }
`;

const AutoComplete = ({ types, type, setType, domain, fetchAgain }) => {
  const loc = useLocation();
  const [query, setQuery] = useState(type);
  const [filteredSuggestions, setFilteredSuggestions] = useState(types.filter((suggestion) => suggestion.toLowerCase().startsWith(query.toLowerCase())));
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [btn, setBtn] = useState(true);

  const putType = async () => {
    const requestBody = {
      typ_name: query
    }
    const requestParams = {
      method: "PUT",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    try{
      const responce = await fetch(`https://${domain}/api/packages/types`, requestParams);
      if(!responce.ok) throw new Error();
      await fetchTypes(fetchAgain, domain);
      setFilteredSuggestions(types);
      setQuery('');
      setShowSuggestions(true);
    }catch(e){
      console.error(e);
    }
  }

  const handleChange = (e) => {
    const userInput = e.target.value;
    const filtered = types.filter(
      (suggestion) => suggestion.toLowerCase().startsWith(userInput.toLowerCase())
    );
    setFilteredSuggestions(userInput !== '' ? filtered : types);
    setQuery(e.target.value);
    agreeToAdd(e.target.value);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    setQuery(suggestion);
    setFilteredSuggestions([suggestion]);
    setShowSuggestions(false);
  };

  const agreeToAdd = (value) => {
    if(value !== ''){
      let boo = true
      for(let i = 0; i < filteredSuggestions.length; i++){
        if(value === filteredSuggestions[i]) {
          boo = false;
          break;
        }
      }
      setBtn(boo);
    }else{
      setBtn(false);
    }
  }

  const btns = loc.pathname === '/packages' && (btn ? <AddButton onClick={putType}> Save new type </AddButton> : <DisButton>Enter new type and press this button</DisButton>) 

  const renderSuggestions = () => {
      return showSuggestions &&  <SuggestionsList>
          {filteredSuggestions.map((suggestion, index) => (
            <Suggestion key={index} onClick={() => handleClick(suggestion)}>
              {suggestion}
            </Suggestion>
          ))}
          {btns}
        </SuggestionsList>
      ;
  };

  useEffect(() => {
    setQuery(type);
    agreeToAdd(type);
  }, [type])

  useEffect(() => {
    fetchTypes(fetchAgain, domain);
    if(query === ''){
      setFilteredSuggestions(types);
    }
    setType(query);
  }, [query])

  const open = () => {
    setShowSuggestions(true);
  }

  return (
    <Container>
      <Input
        onFocus={open}
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Types"
      />
      {renderSuggestions()}
    </Container>
  );
};

export default AutoComplete
