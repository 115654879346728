import {fetchList} from './Payments';

export const fetchPaymentsData = async (email, from_date, to_date, pageNumber, setPackages, domain) => {
    try{
        const requested = await fetchList(email, from_date, to_date, pageNumber, domain);
        setPackages(requested || {});
    }catch(e){
        console.error(e);
        setPackages({})
    }   
}
