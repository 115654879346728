const UserDecodersList = ({decoders}) => {
    const converter = (param) => {
        if (!param.expire) return "Inactive";
        // const arr = param.expire.split('-'); 
        // const months = [
        //     "January", 
        //     "February", 
        //     "March", 
        //     "April", 
        //     "May", 
        //     "June", 
        //     "July", 
        //     "August", 
        //     "September", 
        //     "October", 
        //     "November", 
        //     "December"
        //   ];
        return param.expire
    }
    return(
        <div className="users-decoders-grid">
            {decoders && decoders.map((decoder, decIndex) => (
                <div key={decIndex} className="users-decoder">
                    <div>
                        <div>Decoder id: <span className="pointed">{decoder.decoder_id}</span></div>
                    </div>
                    <table>
                        <thead><tr style={{textAlign:'center'}}><th colSpan={4}>Packages:</th></tr></thead>
                        <tbody>
                          {decoder.packs && decoder.packs.map((pack, packIndex) => (
                              <tr className="decoders-list-row" key={packIndex}>
                                  {/* <td className="td-btn"><input style={status ? { boxShadow: '1px 1px 1px 1px #00000080' } : {}} type="checkbox" disabled={!status} checked={isClicked[index][ind]} onChange={(e) => {toogleChoose(e.target.checked, index, ind)}}/></td>isClicked[index][ind] */}
                                  {/* {acc && <td style={{border: 0, margin: 0, textAlign:"center"}}><input style={{width: "120%"}} type="checkbox" disabled /></td>} */}
                                  <td>{pack.name}</td>
                                  <td>USD <span className="pointed">{pack.cost}</span> per 30 days</td>
                                  <td>Date of {pack.one_time ? ' expiration:' :  ' next activation:'} <span className="pointed">{converter(pack)}</span></td>
                              </tr>   
                          ))}
                        </tbody>
                        {/* <tfoot><tr className="clickable" style={{textAlign:'center'}} onClick={() => {if(status)deleteAll(index)}}><td colSpan={4}>Deactivate all packages</td></tr></tfoot>  */}
                    </table>
                </div>
            ))}
        </div>
    )
}

export default UserDecodersList;