import React, {useEffect, useState} from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalOp = ({name, id, domain}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [list, setList] = useState([])
  const fetchProgs = async () => {
    try{
      const responce = await fetch(`https://${domain}/api/packages/progs/${id}`)
      if(!responce.ok) throw new Error(responce.message);
      const data = await responce.json();
      setList(data.progs || []);
    }catch(e){ console.error(e); }
  }

  useEffect(() => {
    fetchProgs()
  }, [open])

  return (
    <div key={id}>
      <button className='transp-btn' onClick={handleOpen}>{name}</button>
      {open &&         
        <div className='modal-window'>
              <div className='modal-box'>
                <h2>List of programs</h2>
                <div style={{textAlign: 'center'}}>{name}</div>
                {list.map(program => <div>{program}</div>)}
                <button className='closeBtn' onClick={handleClose}>Close</button>
              </div>
        </div>
      }
    </div>
  );
}

export default ModalOp