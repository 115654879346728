const Downloaders = ({fetchTable, fetchLastTable, icon}) => {
    return <table className="excels">
    <tr>
      <td>
        <button
        onClick={() => {fetchTable('balance')}}
        className='reportTableBtns'>Subscribers report</button>
      </td>
      <td className='xls'><img className='xlsIcon' src={icon} alt="" />  Download excel file</td>
    </tr>
    <tr>
      <td>
        <button 
        onClick={() => {fetchTable('decoders-expenses')}}
        className='reportTableBtns'>Decoders report (advanced)</button>
      </td>
      <td className='xls'><img className='xlsIcon' src={icon} alt="" />  Download excel file</td>
    </tr>
    <tr>
      <td>
        <button 
        onClick={() => {fetchTable('manual-payments')}}
        className='reportTableBtns'>Payments added manually</button>
      </td>
      <td className='xls'><img className='xlsIcon' src={icon} alt="" />  Download excel file</td>
    </tr>
    <tr>
      <td>
        <button onClick={() => {fetchLastTable()}} className='reportTableBtns'>Decoders with free tariff</button>
      </td>
      <td className='xls'><img className='xlsIcon' src={icon} alt="" />  Download excel file</td>
    </tr>
  </table>
}

export default Downloaders