import React, {useState} from 'react';
import currs from '../data/Currencies';
import { eight } from './../service/rights/eight';
const Modal = ({selfRightsLvl, fetchAgain, domain}) => {
    const checkCurrency = (currency) => {
        return currs.includes(currency.toUpperCase());
    };
    const [modal, setModal] = useState(false);
    const [errModal, setErrModal] = useState(false);
    const [currency, setCurrency] = useState('');
    const [rate, setRate] = useState(0);

    const currencyChange = (event) => {
        setCurrency(event.target.value)
    };

    const rateChange = (event) => {
        setRate(parseFloat(event.target.value));
      }; 
    const modalSet = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }
    const addNewPackage = async() => {
      const requestBody = {
        currency: currency,
        rate: rate,
        timestamp: null
      }
      const requestParams = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      
      try{
        const responce = await fetch(`https://${domain}/api/currency`, requestParams);
        modalSet();
        setErrModal(false);
        if(!responce.ok) throw new Error();
        fetchAgain();
      }catch(e){
        console.error(e);
      }
    }
    const recq = () => {
        if(checkCurrency(currency)) addNewPackage();
        else setErrModal(true);
    }

    return(
      <div>
        <div className='center-box' style={(selfRightsLvl < 32 ? eight(selfRightsLvl) : true) ? {} : {display: 'none'}}><button className='requestBtn' onClick={modalSet}>Add</button></div>
        {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>Name of currency</label>
                  <input className='inputs' type='text' onChange={currencyChange} />
                </div>
                <div className='input-areas'>
                  <label>Rate to domestic currency</label>
                  <input className='inputs' type='text' onChange={rateChange}/>
                </div>
                {errModal && 'non-existent currency'}
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={recq}>Add</button>
                    <button className='closeBtn' onClick={modalSet}>Cancel</button>
                </div>
            </div>
        </div>}
      </div>
    )
}


export default Modal