import React, {useState, useRef} from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';

const PassChange = ({domain}) => {
    const [curr, setCurr] = useState('')
    const newRef = useRef(null);
    const confirmRef = useRef(null);
    const [newPass, setNewPass] = useState('')
    const [confirm, setConfirm] = useState('')
    const [errorEmpty, setErrorEmpty] = useState(false);
    const [errorCorrect, setErrorCorrect] = useState(false);
    const navigate = useNavigate();

    const handleCurr = (event) => {
        setCurr(event.target.value)
    }
    const handleNew = (event) => {
        setNewPass(event.target.value)
    }
    const handleConfirm = (event) => {
      setConfirm(event.target.value)
    }

    //login fetch request

    const change = async () => {
        const requestBody = {
          password: curr,
	        new_password:newPass,
        };
        if (curr ==='' && newPass === '') {
          setErrorCorrect(false)
          setErrorEmpty(true)
        }else if(newPass !== confirm){
          setErrorCorrect(true)
          setErrorEmpty(false)
        } else {
          setErrorEmpty(false)
          setErrorCorrect(false)
          try {
          const response = await fetch(`https://${domain}/api/operator/passwd`, {
              method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(requestBody)  
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          navigate('/subsribers');
          } catch (error) {
            console.error(error);
            navigate('/subsribers');
          }
        }
    }

    const bluring = (ref) => {
      ref.current.focus();
    }; 

  return (
    <div className='pass-window'>
        <div className='login-box'>
          <h1 className='title'>Password Change</h1>
          <div className='input-areas'>
            <label>Current password</label>
            <input
            className='inputs' type="text" 
            onBlur={() => {bluring(newRef)}} 
            onKeyDown={(e) => {if(e.key === 'Enter') {bluring(newRef)}}}
            onChange={handleCurr}/>
          </div>
          <div className='input-areas'>
            <label>New password</label>
            <input 
            className='inputs' type="text" 
            ref={newRef}
            onBlur={() => {bluring(confirmRef)}} 
            onKeyDown={(e) => {if(e.key === 'Enter') {bluring(confirmRef)}}}
            onChange={handleNew}/>
          </div>
          <div className='input-areas'>
            <label>Confirm password</label>
            <input 
            className='inputs' type="text" 
            ref={confirmRef}
            onBlur={ change}
            onKeyDown={(e) => {if(e.key === 'Enter') { change()}}}
            onChange={handleConfirm}/>
          </div>
          {errorEmpty && <div className='log-error'>Fill the form</div>}
          {errorCorrect && <div className='log-error'>New password is not correct</div>}
          <button style={{marginTop: '5px', width: '100%'}} className='requestBtn' onClick={ change}> Save</button>
        </div>
    </div>
  );
}

export default PassChange