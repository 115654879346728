import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { fetchSubscribersData } from './../service/subscribers/comp';
import { fetchPackagesData } from './../service/packages/comp';
import { fetchDecodersData } from './../service/decoders/comp';
import { fetchPaymentsData } from './../service/payments/comp';
import { fetchCurrencies } from './../service/currencies/comp';
import { fetchOpsData } from './../service/operators/comp';
import { fetchPacksData } from './../service/constructor/compCons';
import { fetchMuxData } from './../service/constructor/compMux';
import Loading from '../components/Loading';

export default function Login({
  setRightsLvl, 
  setSubscribers, 
  setPackages, 
  setDecoders, 
  domain, 
  setOps,
  setMuxes,
  setCurrencies,
  setChecked,
  setPacks,
  // setPayments
}) {
    const [name, setName] = useState('')
    const textFieldRef = useRef(null);
    const [password, setPassword] = useState('')
    const [errorEmpty, setErrorEmpty] = useState(false);
    const [errorCorrect, setErrorCorrect] = useState(false);
    const [modal, setModal] = useState(false)
    const navigate = useNavigate();

    const handleName = (event) => {
        setName(event.target.value)
    }
    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const login = async () => {
        const requestBody = {
            username: name,
            password: password
        };
        if (name ==='' && password === '') {
          setErrorCorrect(false)
          setErrorEmpty(true)
        } else {
          setErrorEmpty(false)
          try {
          const response = await fetch(`https://${domain}/api/operator/login`, {
              method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(requestBody)  
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = response.json();
          setRightsLvl(data.rights || 0);
          setModal(true);
          fetchOpsData(setOps, domain);
          fetchMuxData(setMuxes, domain);
          fetchCurrencies(domain, setCurrencies);
          fetchPacksData(setChecked, setPacks, domain);
          fetchSubscribersData("", "", "", 0, setSubscribers, domain);
          fetchPackagesData("", 0, setPackages, domain);
          fetchDecodersData(null, 0, setDecoders, domain);
          // fetchPaymentsData("", null, null, 0, setPayments, domain)
          
          setTimeout(() => {
            navigate('/subscribers');
            setModal(false);
          }, 2000);
          } catch (error) {
            setErrorCorrect(true);
          }
        }
    }

    const bluring = () => {
      textFieldRef.current.focus();
    }; 
  return (
    <div className='login-window'>
        <div className='login-box'>
          <h1 className='title'>Log In</h1>
          <div className='input-areas'>
            <label >NAME</label>
            <input
            autoFocus
            className='inputs' type="text" 
            onBlur={bluring} 
            onKeyDown={(e) => {if(e.key === 'Enter') {bluring()}}}
            onChange={(event) => {handleName(event)}}/>
          </div>
          <div className='input-areas'>
            <label >PASSWORD</label>
            <input 
            className='inputs' type="password" 
            ref={textFieldRef}
            onBlur={login}
            onKeyDown={(e) => {if(e.key === 'Enter') {login()}}}
            onChange={(event) => {handlePassword(event)}}/>
          </div>
          {errorEmpty && <div className='log-error'>Fill the form</div>}
          {errorCorrect && <div className='log-error'>User name or password is not correct</div>}
          <button style={{marginTop: '5px', width: '100%'}} className='requestBtn' onClick={login}> Log In</button>
        </div>
        {modal && <Loading />}
    </div>
  );
}